import React from 'react';
import Layout from '../components/layout';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PageHeader from '../components/page-header';

const AboutTemplate = ({ data }) => {
  const contacts = data.allMarkdownRemark.nodes;
  let contactOrder = ['chris', 'gertjan'];
  let contactName = (contact) => contact.fields.slug.match('\/contacts\/(.*)\/')[1];
  let sortedContacts = contacts.sort((a, b) => contactOrder.indexOf(contactName(a)) > contactOrder.indexOf(contactName(b)));

  const Contacts = sortedContacts.map(({ frontmatter, fields, html }, index) => {
    const {name, image} = frontmatter;
    const { slug } = fields;

    return (
      <AboutWrapper key={slug} className={(index % 2) ? '' : 'odd'}>
        <AboutCopy key='body' dangerouslySetInnerHTML={{ __html: html }} />
        <AboutImageWrapper key='image' Img={`${image}-/scale_crop/500x500/`} alt="" />
      </AboutWrapper>
    );
  });

  return (
    <Layout title="Wie is Hedaloc?">
      <PageHeader header>
        <h1>Wie is Hedaloc?</h1>
      </PageHeader>
      {Contacts}
    </Layout>
  );
};

export default AboutTemplate;

const AboutWrapper = styled.div.attrs(props => ({
  className: props.className
}))`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  margin-top: var(--size-700);

  &.odd {
    flex-direction: row-reverse;
  }

  @media screen and (max-width: 1000px) {
    & {
      flex-direction: column-reverse;
    }

    &.odd {
      flex-direction: column-reverse;
    }

    & > * {
      margin-top: 2rem;
      width: 100%;
      text-align: center;
    }
  }
`;

const AboutImageWrapper = styled.img.attrs(props => ({
  src: props.Img,
}))`
  display: block;
  border-radius: 50%;
  height: 300px;
  width: 300px;
`;

const AboutCopy = styled.div`
  max-width: 60ch;

  & p {
    font-size: var(--size-400);
  }

  & > * + * {
    margin-top: var(--size-300);
  }

  & > p + p {
    margin-top: var(--size-300);
  }
`;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fields: { contentType: { eq: "contacts" }, slug: { ne: "/contacts/hedaloc/"} } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      nodes {
        html
        fields {
          slug
        }
        frontmatter {
          image
        }
      }
    }
  }
`;
